import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useParams, Router, useLocation } from '@reach/router';
import { Box } from 'grommet/components/Box';
import { Button as GButton } from 'grommet/components/Button';
import { Heading } from 'grommet/components/Heading';
import tw from 'twin.macro';
import Button from 'components/Button';
import Text from 'components/Text';
import Tabs from 'components/Tabs';
import Col from 'components/Col';
import Row from 'components/Row';
import EmptyState from 'components/EmptyState';
import TicketTypeRow from 'components/TicketTypeRow';
import DashboardTab from 'components/DashboardTab';
import { decode } from 'js-base64';
import OrderList from 'components/OrderList';
import { isBrowser } from 'utils/browser';
import AttendeeList from 'components/AttendeeList';
import AccordionList from 'components/AccordionList';
import { TicketApi } from 'services/api/ticket-api';
import { useApi } from 'providers/ApiProvider';
import moment from 'moment';
import { navigate } from 'gatsby';
import { EventDashboardHeader } from 'tutadoo-design-system/src/components/EventDashboardHeader';
import { EventPayoutTab } from 'tutadoo-design-system/src/components/EventPayoutTab';
import { PayoutStatus } from 'tutadoo-design-system/src/components/PayoutStatus';
import { Modal as OModal } from 'tutadoo-design-system/src/components/Modal';
import { TicketEdit } from 'tutadoo-design-system/src/components/TicketEdit';
import Modal from 'components/Modal';
import useTicketEdit from 'hooks/useTicketEdit';
import useToast from 'tutadoo-design-system/src/hooks/useToast';
import { OrganizationService } from 'services/organizations';
import { IPayoutMethod, IEventPayout } from 'tutadoo-design-system/src/services';
import { EventService } from 'services/events';
import { EventApi } from 'services/api/event-api';
import { useAuth0 } from '@auth0/auth0-react';
import { EventCreate } from 'components/EventCreate';
import { DroppedOrderList } from 'components/DroppedOrderList';
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort';
import arrayMove from 'array-move';
import { Grip } from 'lucide-react';
import { AuthContext } from '../contexts/AuthContext';
import EventAnimation from './EventAnimation';
import { getEvent } from '../services/hasura';

const DashboardWrapper = tw.div`h-full py-0 border-t-2 lg:py-10 lg:border-none sm:rounded-none`;
const Content = tw.div`h-full overflow-scroll bg-white`;
const HelpTabWrapper = tw(Col)`py-28`;

export const PayoutTab = ({ event, onStartPayout, onAddPayoutMethod }) => {
  return (
    <div className="px-5 py-4">
      <EventPayoutTab
        account={
          event.organization.organization_accounts.length > 0 &&
          event.organization.organization_accounts[0]
        }
        payoutAllowed
        status={event.event_payout?.status || 'none'}
        currency="KES"
        amount={event.orders_aggregate.aggregate.sum.total}
        payoutDate={moment(event.end_date).toDate()}
        payoutPeriod="4 - 5"
        onStartPayout={onStartPayout}
        onAddPayoutMethod={onAddPayoutMethod}
      />
    </div>
  );
};
export const HelpTab = ({ event }) => {
  //   const Tawk_API = Tawk_API || {};
  return (
    <HelpTabWrapper centered>
      <Row vCentered centered collapse spacing="sm">
        <Text label="Please send an email to help@tutadoo.com" large bold />
      </Row>
      <Col spacing="sm">
        <Text label="OR" />
      </Col>
      {isBrowser && (
        <Button
          onClick={() => window.Tawk_API && Tawk_API.toggle()}
          label="Leave a message"
          primary
        />
      )}
    </HelpTabWrapper>
  );
};
export const DetailsTab = ({ event }) => {
  const f = ['name', 'category', 'description', 'start_date', 'end_date', 'venue', 'status'].map(
    (key) => {
      let data = event[key];
      if (key === 'description') {
        data = decode(data.split('b64:')[1]);
      }
      return (
        <Row vCentered collapse spacing="sm">
          <Text label={key} medium bold />
          <Text label={`${data}`} />
        </Row>
      );
    }
  );
  return (
    <>
      <Col spacing="sm" spacex>
        {f}
      </Col>
    </>
  );
};
export const TicketsTab = ({ event, reload }: { event: namespace.Event; reload }) => {
  const { api } = useApi();
  const [showModal, setShowModal] = useState(event?.ticket_types?.length === 0);
  const [loading, setLoading] = useState(null);
  const [editTicket, setEditTicket] = useState(null);
  const [ticketTypes, setTicketTypes] = useState(event?.ticket_types || []);
  const {
    onSubmit,
    loading: submitLoading,
    success: submitSuccess
  } = useTicketEdit(event, editTicket);

  useEffect(() => {
    if (submitLoading) return;
    if (submitSuccess) {
      setShowModal(false);
    }
  }, [submitLoading, submitSuccess]);

  useEffect(() => {
    if (showModal === false) {
      setEditTicket(null);
      reload();
    }
  }, [showModal]);

  useEffect(() => {
    if (editTicket !== null) {
      setShowModal(true);
    }
  }, [editTicket]);

  const hasTickets = (event) => {
    return event.ticket_groups.length > 0 || ticketTypes.length > 0;
  };

  const onHideTicket = async ({ id, status }) => {
    if (loading) return;
    try {
      const ticketApi = new TicketApi(api);
      let newStatus = status;
      if (status === 'visible') {
        newStatus = 'hidden';
      } else {
        newStatus = 'visible';
      }
      setLoading(id);
      const result = await ticketApi.setStatus(id, newStatus);
      if (result.kind === 'bad-data') {
        alert('Failed: Please try again');
      } else if (result.kind === 'ok') {
        // reload();
        if (ticketTypes?.length > 0) {
          const objIndex = ticketTypes.findIndex((obj) => obj.id === id);
          ticketTypes[objIndex].status = newStatus;
        }
      }
    } catch (error) {
      alert('Please try again');
    }
    setLoading(null);
  };
  const onSort = async ({ items, lastItems }) => {
    if (loading) return;
    setTicketTypes(items);
    try {
      const ticketApi = new TicketApi(api);
      setLoading(true);
      const result = await ticketApi.updateSort(items.map(({ id }, ix) => ({ id, index: ix })));
      if (result.kind === 'bad-data') {
        setTicketTypes(lastItems);
      } else if (result.kind === 'ok') {
        // reload();
      }
    } catch (error) {
      setTicketTypes(lastItems);
    }
    setLoading(null);
  };
  return (
    <>
      {!hasTickets(event) && (
        <EmptyState
          onAction={() => {
            setShowModal(true);
          }}
        />
      )}
      <OModal show={showModal} close={() => setShowModal(false)} title="" editFooter={false}>
        <TicketEdit
          mode={editTicket ? 'update' : 'add'}
          onCancel={() => setShowModal(false)}
          initialValues={editTicket}
          onSubmit={onSubmit}
          loading={submitLoading}
          eventEndDate={event.end_date}
          eventStartDate={event.start_date}
        />
      </OModal>
      {hasTickets(event) && (
        <Box
          margin={{ vertical: 'medium', horizontal: 'large' }}
          justify="between"
          direction="row"
          align="center"
        >
          <Heading level="3" color="purple-800">
            Ticket Types
          </Heading>
          <GButton
            primary
            label="Add Tickets"
            onClick={() => {
              setShowModal(true);
            }}
          />
        </Box>
      )}
      <Box background="dark-grey-100" height="1px" fill="horizontal" />
      <TicketList
        ticketTypes={ticketTypes}
        loading={loading}
        setEditTicket={setEditTicket}
        onHideTicket={onHideTicket}
        onSort={onSort}
      />
    </>
  );
};

const TicketList = ({
  ticketTypes,
  loading,
  setEditTicket,
  onHideTicket,
  onSort
}: {
  ticketTypes: any[];
  loading: any;
  setEditTicket: React.Dispatch<any>;
  onHideTicket: React.Dispatch<any>;
  onSort: React.Dispatch<any>;
}) => {
  const [lastItems, setLastItems] = useState(ticketTypes || []);
  const [items, setItems] = useState(ticketTypes || []);

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    setLastItems(items);
    setItems((array) => arrayMove(array, oldIndex, newIndex));
  };

  useEffect(() => {
    const lastHash = lastItems.map(({ id }) => id).join();
    const currentHash = items.map(({ id }) => id).join();
    if (!lastHash.match(currentHash)) {
      onSort({ items, lastItems });
    }
  }, [lastItems, items]);

  useEffect(() => {
    console.log(ticketTypes.map(({ id }) => id));
    setItems(ticketTypes);
    // setLastItems(ticketTypes);
  }, [ticketTypes]);

  return (
    <div>
      <SortableList
        onSortEnd={onSortEnd}
        className="divide-y-2 divide-gray-100 divide-solid"
        draggedItemClassName="bg-slate-50"
        lockAxis="y"
      >
        {items.map((item) => (
          <SortableItem key={item.id}>
            <div className="flex flex-row justify-start items-center gap-2 px-8 py-4">
              <SortableKnob>
                <Grip className="cursor-pointer" />
              </SortableKnob>
              <div className="flex w-full grow">
                <TicketTypeRow
                  loading={item.id === loading}
                  ticketType={item}
                  onEdit={() => setEditTicket(item)}
                  onHide={() => onHideTicket(item)}
                />
              </div>
            </div>
          </SortableItem>
        ))}
      </SortableList>
      {/* <AccordionList
        items={event.ticket_groups}
        renderItem={(item) => (
          <Col>
            <TicketTypeRow
              key={item.id}
              ticketType={item}
              loading={item.id === loading}
              onEdit={() => setEditTicket(item)}
              onHide={() => onHideTicket(item)}
            />
          </Col>
        )}
      /> */}
    </div>
  );
};

export default function EventDashboard() {
  let event;
  const params = useParams();
  const { pathname } = useLocation();
  const {
    user,
    isAuthenticated,
    authenticatorLoading,
    loading: userLoading
  } = useContext(AuthContext);
  const tp = pathname.replace(`${params.tab}`, '');
  const { toast, renderToast, renderLoader } = useToast();
  const { api } = useApi();
  const { getIdTokenClaims } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [addPayoutMethod, { loading: addLoading }] = useMutation<
    { insert_organization_account_one: IPayoutMethod },
    { newPayout: IPayoutMethod }
  >(OrganizationService.addPayoutMethod, {
    update(store, { data }) {
      const payoutData = store.readQuery<{ payoutMethods: IPayoutMethod[] }>({
        query: OrganizationService.getAllPayouts(
          data.insert_organization_account_one.organization_id
        )
      });
      store.writeQuery<{ payoutMethods: IPayoutMethod[] }>({
        query: OrganizationService.getAllPayouts(
          data.insert_organization_account_one.organization_id
        ),
        data: {
          payoutMethods: [...payoutData.payoutMethods, data.insert_organization_account_one]
        }
      });
    },
    onCompleted: () => {
      window.location.reload();
    }
  });

  const onAddPayout = (value: IPayoutMethod) => {
    const newPayout = { ...value, organization_id: event.organization_id };
    addPayoutMethod({ variables: { newPayout } })
      .then(() => {
        toast.success('New Payout method added succesfully');
      })
      .catch((err) => {
        toast.error(err?.message, 'Failed to add payout method');
      });
  };

  const [addEventPayout, { loading: payoutLoading }] = useMutation<
    { insert_event_payout_one: IEventPayout },
    { newEventPayout: IEventPayout }
  >(EventService.addEventPayout, {
    onCompleted: () => {
      window.location.reload();
    }
  });

  const onStartPayout = (value) => {
    const newEventPayout = {
      organization_account_id: event.organization.organization_accounts[0].id,
      event_id: event.uid
    };
    addEventPayout({ variables: { newEventPayout } })
      .then(() => {
        toast.success('Payout started successfully');
      })
      .catch((err) => {
        toast.error(err?.message, 'Failed to start payout');
      });
  };

  const resp = useQuery(getEvent(params.id, true), {
    // pollInterval: 5000,
    fetchPolicy: 'no-cache'
  });

  if (resp.loading) return <EventAnimation />;
  const onToggleStatus = async (option) => {
    if (loading) return;
    try {
      const claims = await getIdTokenClaims();
      const eventAPI = new EventApi(api, claims.__raw);
      const result = await eventAPI.setStatus(event.id, option);
      if (result.kind === 'bad-data') {
        alert('Failed: Please try again');
      } else if (result.kind === 'ok') {
        resp.refetch();
      }
    } catch (error) {
      alert('Please try again');
    }
    setLoading(false);
  };

  event = resp.data?.event;
  if (!isAuthenticated) {
    navigate(`/login?returnTo=${window.location.pathname}`);
    return <EventAnimation />;
  }

  if (authenticatorLoading || userLoading) return <EventAnimation />;
  if (user.userInfo.id !== event.organizer.user_id && !process.env.ADMIN) {
    navigate('/');
    return <EventAnimation />;
  }

  event.orders.forEach((order, i) => {
    event.orders[i].tickets = order.items
      .map(({ name, quantity }) => `${quantity} x ${name}`)
      .join(', ');
    if (event.orders[i].answers) {
      event.orders[i] = { ...event.orders[i].answers, ...event.orders[i] };
    }
    event.orders[i].booking_time = moment(event.orders[i].created_at).format(
      'YYYY-MM-DD  h:MM:SS A'
    );
    event.orders[i].venue = event.venue?.address || event.address;
  });

  event.failedOrders.forEach((order, i) => {
    event.failedOrders[i].tickets = order.items
      .map(({ name, quantity }) => `${quantity} x ${name}`)
      .join(', ');
    if (event.failedOrders[i].answers) {
      event.failedOrders[i] = { ...event.failedOrders[i].answers, ...event.failedOrders[i] };
    }
    event.failedOrders[i].booking_time = moment(event.failedOrders[i].created_at).format(
      'YYYY-MM-DD  h:MM:SS A'
    );
    event.failedOrders[i].venue = event.venue?.address || event.address;
    event.failedOrders[i].reason = event.failedOrders[i].payment?.reason ?? '';
  });

  event.attendees.forEach((attendee, i) => {
    event.attendees[i].booking_time = moment(event.attendees[i].created_at).format(
      'YYYY-MM-DD  h:MM:SS A'
    );
    event.attendees[i].selected_day = moment(event.attendees[i].day).format('YYYY-MM-DD');
    event.attendees[i].venue = event.venue?.address || event.address;
  });

  event.ticket_types.forEach((ticket, i) => {
    if (event.ticket_types[i].questions?.length > 0) {
      event.ticket_types[i].questions = ticket.questions.map((name) => {
        if (typeof name === 'string') {
          return { name };
        }
        return name;
      });
    }
  });
  const tabs = ['Home', 'Ticket-Types', 'Bookings', 'Attendees', 'Dropped'];
  if (event.organization) tabs.push('Payout');
  // tabs.push('Help');
  return (
    <>
      {showEditModal && (
        <Modal
          closeModal={() => {
            setShowEditModal(false);
          }}
        >
          <EventCreate
            event={event}
            onCancel={() => {
              setShowEditModal(false);
              resp.refetch();
            }}
          />
        </Modal>
      )}
      <DashboardWrapper>
        <EventDashboardHeader
          event={event}
          onEditEvent={() => {
            setShowEditModal(true);
          }}
          updateEventStatus={onToggleStatus}
        />
        <Tabs items={tabs} pathname={tp.replace(/\/$/, '')} />
        <Content>
          <Router basepath={tp}>
            <DashboardTab path="/home" event={event} user={user} />
            <DashboardTab path="/dashboard" event={event} user={user} />
            <TicketsTab path="/ticket-types" event={event} reload={resp.refetch} />
            {/* <DetailsTab path="details" event={event} /> */}
            <OrderList path="bookings" event={event} />
            <AttendeeList path="/attendees" event={event} />
            <AttendeeList path="/tickets" event={event} />
            <DroppedOrderList path="/dropped" event={event} />
            {event.organization && (
              <PayoutTab
                path="/payout"
                event={event}
                onStartPayout={onStartPayout}
                onAddPayoutMethod={onAddPayout}
              />
            )}
            <HelpTab path="help" event={event} />
          </Router>
        </Content>
        {renderToast()}
        {renderLoader(addLoading, 'creating payout')}
        {renderLoader(payoutLoading, 'starting payout')}
      </DashboardWrapper>
    </>
  );
}

import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { Button } from '.';
import Col from './Col';
import TicketButton from './icons/components/TicketButton';
import Row from './Row';
import Text from './Text';

const Description = styled(Text)(() => [tw`w-7/12`]);

const Footer = styled(Row)(() => [tw`flex-row h-12 w-full justify-center align-middle`]);

const EmptyStateContainer = styled(Col)(() => [
  tw`px-4 md:px-0 py-6 md:py-12 shadow-lg rounded-lg md:mx-32 my-20`
]);

function EmptyState({ onAction }) {
  return (
    <EmptyStateContainer centered spacing="sm">
      <Text label="Every great event needs tickets" bolder />
      <Description
        label="You havn’t created any tickets. To let people signup for your event click create ticket below"
        small
        spacing="sm"
      />
      <Footer spacing="sm">
        <TicketButton />
        <Button primary bold small onClick={onAction}>
          CREATE TICKET
        </Button>
      </Footer>
    </EmptyStateContainer>
  );
}

EmptyState.defaultProps = {
  onAction: Function
};
export default EmptyState;

import CreateTicketState from '../states/CreateTicketState';

export const CreateTicketAction = (state, payload) => {
  const updates = {};
  Object.keys(payload).forEach((k, v) => {
    updates[k] = { ...state.CreateTicketState[k], value: payload[k], defaultValue: payload[k] };
  });

  return {
    ...state,
    CreateTicketState: {
      ...state.CreateTicketState,
      data: { ...state.CreateTicketState, ...updates }
    }
  };
};

export const clearTicketForm = (state) => {
  return {
    ...state,
    CreateTicketState
  };
};

import { DocumentNode, gql } from '@apollo/client';
import { standardEventQueryResponse } from 'services/hasura';

export interface IEventService {
  addEventPayout: DocumentNode;
}

export const EventService: IEventService = class {
  static addEventPayout = gql`
    mutation addEventPayout($newEventPayout: event_payouts_insert_input!) {
      insert_event_payouts_one(object: $newEventPayout) {
        organization_account_id
      }
    }
  `;
};

export default EventService;

import { useAuth0 } from '@auth0/auth0-react';
import { navigate } from 'gatsby';
import { useStateMachine } from 'little-state-machine';
import { useApi } from 'providers/ApiProvider';
import { useState } from 'react';
import { CreateTicketResult, TicketApi } from 'services/api';
import { CreateTicketAction, clearTicketForm } from '../store/actions/CreateTicketAction';

export function useTicketEdit(
  event: { id: number },
  ticket: { id: number }
): { onSubmit; loading; success } {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { api } = useApi();
  const { actions, state } = useStateMachine({ CreateTicketAction, clearTicketForm });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = async (newdata: any) => {
    const data = { ...newdata, price: newdata.type === 'free' ? 0 : newdata.price };
    setSuccess(false);
    actions.CreateTicketAction(data);
    setLoading(true);
    if (!isAuthenticated) {
      loginWithRedirect({
        redirectUri: `${process.env.GATSBY_AUTH0_CALLBACK}/profile`,
        screen_hint: 'signup'
      });
      return;
    }
    const ticketAPI = new TicketApi(api);
    let result: CreateTicketResult;
    if (!ticket) {
      result = await ticketAPI.createTicketType({ event: event.id, ...data });
    } else {
      result = await ticketAPI.updateTicketType({ id: ticket.id, ...data });
    }
    if (result.kind === 'bad-data') {
      alert('Failed: Please try again');
    } else {
      actions.clearTicketForm(null);
      navigate(`/host/event/dashboard/${event.id}/ticket-types/`, {
        replace: true
      });
    }
    setLoading(false);
    setSuccess(true);
  };

  return { onSubmit, loading, success };
}

export default useTicketEdit;
